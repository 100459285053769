import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {Card, CardContent, Grid, Typography} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  goal: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4),
    },
    fontSize: '1.5rem',
    textAlign: 'left',
    '& p': {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
      },
    },
  },
}));

const Goal = ({ header, children }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.goal}>
      <Card raised>
        <CardContent>
          <Typography variant="h4">{header}</Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};

Goal.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Goal.defaultProps = {
  children: null,
};

export default Goal;
