import React from 'react';
import ReactDOM from 'react-dom';
import {createTheme, ThemeProvider} from "@mui/material";
import AppBar from '../components/shared/app_bar';
import UnderConstruction from '../components/under_construction';

const theme = createTheme();

const App = () => (
  <ThemeProvider theme={theme}>
    <div>
      <AppBar />
      <UnderConstruction />
    </div>
  </ThemeProvider>
);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement('div')),
  );
});
