import React from 'react';
import { makeStyles } from '@mui/styles';
import { Construction } from '@mui/icons-material';
import { Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Goal from './goal';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: '74px',
  },
  constructionIcon: {
    padding: theme.spacing(2),
    fontSize: '4rem',
    verticalAlign: 'middle',
  },
}));

const P = ({ children }) => (
  <Typography variant="body" color="text.secondary" component="p">
    {children}
  </Typography>
);

P.propTypes = {
  children: PropTypes.node,
};

P.defaultProps = {
  children: null,
};

const UnderConstruction = () => {
  const classes = useStyles();

  return (
    <Grid container rowSpacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h2">Welcome!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          This page is currently under construction but coming soon.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Construction
          classes={{ root: classes.constructionIcon }}
          color="warning"
        />
      </Grid>
      <Goal header="Mission">
        <P>
          We aim to allow JetBlue customers to post their complaints and stories
          for all to see. This website is being created in the hopes we can
          organize, share, and combine efforts to resolve our issues.
        </P>
      </Goal>
      <Goal header="Immediate actions">
        <P>
          If you have been wronged by JetBlue you should first
          {' '}
          <a href="https://airconsumer.dot.gov/escomplaint/ConsumerForm.cfm">
            file a complaint with the DOT.
          </a>
        </P>
      </Goal>
      <Goal header="Our story">
        <P>
          My wife and I were traveling home to give birth. She is a high risk
          pregnancy so we wanted to be back home with friends and family for
          support. We have a service dog (Chewie) for her that we fly with all
          the time even on JetBlue that is perfectly behaved and meets all of
          the
          {' '}
          <a href="https://www.transportation.gov/individuals/aviation-consumer-protection/service-animals">
            updated DOT rules
          </a>
          {' '}
          according to the
          {' '}
          <a href="https://www.transportation.gov/tags/air-carrier-access-act">
            ACAA
          </a>
          .
        </P>
        <P>
          Upon getting to the airport to check in one of the agents at the
          counter overheard us explain that the dog is trained to detect and
          mitigate harmful self inflicting behavior and wake my wife up in
          certain situations. The CRO (Kandy) at San Diego airport decided that
          my wife's service dog does not count. She provided no information on
          how Chewie did not qualify. She was clearly just on a power trip for
          whatever reason.
        </P>
        <P>
          We were not able to board the flight so we contacted their complaints
          department at which point we were told they will always go based off
          of the determination by an agent on the ground. This is alarming
          because
          {' '}
          <a href="https://www.nbcmiami.com/responds/couple-says-airline-didnt-allow-service-dogs-on-flight/2445494/">
            JetBlue agents are inconsistent and can clearly make mistakes when
            determining if you can be allowed on a flight.
          </a>
        </P>
        <P>
          Essentially agents on the ground can discriminate against you for any
          reason they want, don't need ot explain themselves, and support cannot
          help you. This company can break the law and because the ACAA does not
          allow for damages to be awarded in lawsuits, there are no consequences
          for them! It also means there is nothing you can really do if you're
          subjectively discriminated against for your service animal.
        </P>
        <P>
          The procedure makes no sense. The trained task the dog performs does
          not change every time you fly. Why can't the trained task be
          pre-approved? Obviously behavior of the animal must be observed on
          site but the trained task should not be left up to such subjective
          reasoning.
        </P>
        <P>
          Kandy the JetBlue agent disgusts me, she treated a pregnant woman who
          explained that she has psychiatric issues that the dog is trained to
          mitigate horribly denying her entry to the flight with no explanation.
          Now JetBlue says that they cannot guarantee that if we fly with them
          and follow all the rules that we will be able to fly back with our
          service dog. I have never encountered such horrible customer service,
          no resolution or even concrete explanation can be provided. Hopefully
          my story along with others can help push the change needed.
        </P>
      </Goal>
      <Goal header="Issues with other airlines?">
        <P>
          After the site is built, if we see there is demand for a modern
          website to serve more air travelers beyond JetBlue customers, we can
          add that functionality. We bought airline-complaints.com just in case
          we need to expand.
        </P>
        <P>
          For now use
          {' '}
          <a href="https://airlinecomplaints.org">airlinecomplaints.org</a>
        </P>
      </Goal>
    </Grid>
  );
};

export default UnderConstruction;
